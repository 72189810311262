import React from 'react';
import PropTypes from 'prop-types';

import TrendsExpandable from '@seo-frontend-components/trends-expandable';

import { useStaticProps } from '../../components/context/static-props';
import { UI_CATEGORY_TRENDS_EXPANDABLE as namespace } from '../../constants';

/**
 * @typedef {Object} CategoryTrendExpandableProps
 * @property {boolean} preload - Indicates if the component should preload data.
 * @property {Object} trends_categories - The trends categories data.
 */

const CategoryTrendExpandable = (props) => {
  const { trends_categories, preload } = props;
  const { deviceType, jsDisabled, webp: supportWebP } = useStaticProps();

  return (
    <section className={namespace}>
      <TrendsExpandable
        trends_categories={trends_categories}
        preload={preload}
        deviceType={deviceType}
        jsDisabled={jsDisabled}
        supportWebP={supportWebP}
      />
    </section>
  );
};

CategoryTrendExpandable.propTypes = {
  preload: PropTypes.bool.isRequired,
  trends_categories: PropTypes.shape({}),
};

export default CategoryTrendExpandable;
